import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from "@/store";
Vue.use(VueI18n);
const i18nObj = new VueI18n({
    locale: localStorage.getItem('language') || "en-US",
    // 修改messages需要同步修改Main.js对应的messages.
    messages: {
        'en-US': require("../../assets/lang/en.js"),
        'zh-CN': require("../../assets/lang/zh-CN.js"),
        'zh-TW': require("../../assets/lang/zh-TW.js")
    }
});
const i18nTc = (msg, params) => {
    i18nObj.locale = store.getters['i18n/localLanguage'];
    if (params)
        return i18nObj.t(msg, params);
    return i18nObj.tc(msg);
};

export default {
    /*---  check 0-9 + ( ) ---*/
    checkPhoneNumber: (rule, value, callback) => {
        let pattern = /^[0-9+\(\)-]{1,}$/;
        if (value && value !== "" && !pattern.test(value)) {
            let tips = i18nTc('globalText.itShouldBeFormedBy_X', {value: '0-9 + ( ) -'}); //"It should be formed by 0-9 + ( ) -"
            callback(new Error(tips));
        } else {
            callback();
        }
    },
    /*---  check 0-9 + ( ) ---*/
    checkLicenseNumber: (rule, value, callback) => {
        let pattern = /^[A-Za-z0-9<-]{1,}$/;
        if (value && value !== "" && !pattern.test(value)) {
            let tips = i18nTc('globalText.itShouldBeFormedBy_X', {value: 'A-Z a-z 0-9 < -'}); //"It should be formed by A-Z a-z 0-9 < -"
            callback(new Error(tips));
        } else {
            callback();
        }
    },
    checkPlateNumber: (rule, value, callback) => {
        let pattern = /^[A-Za-z0-9-]{1,}$/;
        if (value && value !== "" && !pattern.test(value)) {
            let tips = i18nTc('globalText.itShouldBeFormedBy_X', {value: 'A-Z a-z 0-9 < -'}); //"It should be formed by A-Z a-z 0-9 < -"
            callback(new Error(tips));
        } else {
            callback();
        }
    },
    checkFuelType: (rule, value, callback) => {
        let pattern = /^[A-Za-z0-9#]{1,}$/;
        if (value && value !== "" && !pattern.test(value)) {
            let tips = i18nTc('globalText.itShouldBeFormedBy_X', {value: 'A-Z a-z 0-9 #'}); //"It should be formed by A-Z a-z 0-9 #"
            callback(new Error(tips));
        } else {
            callback();
        }
    },
    checkNumberOnly(rule, value, callback) {
        let pattern = /^\d+$/;
        if (value && value !== "" && !pattern.test(value)) {
            callback(new Error("Number Only"));
        } else {
            callback();
        }
    },
    checkFloatOne(rule, value, callback) {
        let pattern = /^[0-9]+(\.[0-9]{1,1})?$/;
        if (value && value !== "" && !pattern.test(value)) {
            let tips = i18nTc('globalText.theValueCanHaveOneDecimalPlaceOnly'); // "The value can have one decimal place only";
            callback(new Error(tips));
        } else {
            callback();
        }
    },
    inputNoSpace: (value) => {
        return value.replace(/\s+/g, '')

    },
    checkNumber(value) {
        value = value.replace(/[^\d.]/g, '');
        if (value !== '' && !isNaN(parseInt(value)))
            return parseInt(value);
        return '';
    },
    inputFloatOne(value) {
        value = value.replace(/[^\d.]/g, '');
        value = value.replace(/^\./g, '');
        value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');

        value = value.replace(/^0+/, "0");
        value = value.replace(/^0+([1-9])/, "$1");
        value = value.replace(/\.{2,}/g, '');
        let index = -1
        for (let i in value) {
            if (value[i] === '.') {
                index = i
            }
            if (index !== -1) {
                if (i - index > 1) {
                    value = value.substring(0, value.length - 1)
                }
            }
        }
        return value;
    },
    checkRange: (rule, value, callback, min, max) => {
        if (value === '') callback();
        let val = parseFloat(value);
        if (val < min || val > max) {
            let tips = i18nTc('globalText.theValueShouldBeBetween_X_And_Y', {min: min, max: max});
            callback(new Error(tips));
        } else {
            callback();
        }
    }

}
