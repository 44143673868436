<template>
    <div class="simple-layout">
        <div class="header">
            <span class="via-mobile360-logo via-logo"></span>
            <span class="company-title">VIA FLEET</span>
        </div>
        <div class="middle">
            <div slot="content" class="layout-content">
                <div class="title">Reset Password</div>
                <slot name="content"></slot>
            </div>
        </div>
        <div class="footer">
            <p>Copyright © {{ year }} VIA Technologies, Inc. All Rights Reserved.</p>
            <p>Version 1.0.1</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ResetPwdLayout",
        computed: {
            year() {
                return new Date().getFullYear();
            }
        },
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl";
    .simple-layout
        width 100%
        height 100%
        position relative
        background-color $color_gray_first

    .header
        height: 75px;
        line-height: 75px;
        font-size: 22px;
        color: $color_white;
        background: $color_blue_first;
        padding-left 30px
        .via-logo
            font-size: 40px;
            color: white;
            vertical-align: middle
        .company-title
            margin-left 10px
            vertical-align: middle

    .middle
        position absolute;
        width 100%
        top: 75px;
        bottom: 100px;
        .layout-content
             width 520px
             height 400px
             background-color white
             top: 50%;
             left: 50%
             position absolute;
             margin-top -200px
             margin-left -260px
             border 1px solid $color_gray_third
             border-radius 5px
             padding 30px 40px
             box-sizing border-box
             .title
                font-size 18px
                font-weight 600
                text-align center
    .footer
        position absolute
        bottom: 33px;
        height 50px
        line-height 25px
        width 100%
        color $color_black_third
        text-align center
        background-color $ry_content_before_bg_color
</style>