<template>
    <div>
        <el-input size="small" v-model="password" class="password-input" :style="{maxWidth:width}"
                  @input="passwordInput()" auto-complete="new-password"
                  :type="isShowPassword ? 'password':'text'"></el-input>
        <i class="view-button" v-show="showLockIcon"
           :class="{'el-icon-lock':isShowPassword,'el-icon-unlock':!isShowPassword}" @click="showPassword"></i>
    </div>

</template>

<script>
    import regExp from "@/assets/js/RegExp";

    export default {
        name: "PasswordInput",
        props: {
            pwd: {type: String, default: ''},
            showLockIcon: {
                type: Boolean, default: false
            },
            width: {type: String, default: '300px'}
        },
        model: {
            prop: 'pwd',
            event: 'passwordChange',
        },
        data() {
            return {
                isShowPassword: true,
                password: '',
                readonly: false
            }
        },
        methods: {
            showPassword() {
                this.isShowPassword = !this.isShowPassword;
            },
            passwordInput() {
                this.password = regExp.inputNoSpace(this.password);
                this.$emit("passwordChange", this.password);
            }

        }
    }
</script>

<style scoped lang="stylus">

    .view-button
        margin-left 10px
        width 50px
</style>
