<template>
    <reset-pwd-layout>
        <el-form slot="content" ref='resetPwdForm' :rules="resetPwdRules" :model="passwordInfo" label-width="146px"
                 label-position="left" v-if="!success">
            <div class="info-area">
                <span>Please enter your new Password:</span><br>
                <span>(6-15 characters,case sensitive)</span>
            </div>
            <el-form-item class="password-area" :label="newPassword" prop="password">
                <password-input :show-lock-icon="true"  width="200px" v-model="passwordInfo.password"></password-input>
            </el-form-item>
            <el-form-item class="reset-password-area" :label="reEnterPassword" prop="reEnterPassword">
                <password-input :show-lock-icon="true"  width="200px"  v-model="passwordInfo.reEnterPassword"></password-input>
            </el-form-item>
            <div class="button-area">
                <el-button size="middle" class="submit-button" @click="submit">Submit
                </el-button>
            </div>
        </el-form>


        <div  v-else slot="content" class="success-content">
            <i class="el-icon-check success-icon"></i>
            <div class="success-info">{{$t('popUpMsg.resetPwd.yourPasswordHasBeenUpdatedPleaseLogin')}}</div>
<!--            <div class="success-info">Your password has been updated.</div>-->
<!--            <div  class="success-info">please login</div>-->
            <div class="button-area" style="margin-top: 90px">
                <el-button size="middle" class="submit-button" @click="login">Login
                </el-button>
            </div>
        </div>
    </reset-pwd-layout>
</template>

<script>
    import ResetPwdLayout from "@/components/login/ResetPwdLayout";
    import PasswordInput from "@/components/common/PasswordInput";

    export default {
        name: "ResetPassword",
        components: {PasswordInput, ResetPwdLayout},
        data() {
            var checkPassword = (rule, value, callback) => {
                if (value !== this.passwordInfo.password) {
                    callback(new Error('The two passwords are inconsistent'));
                } else {
                    callback();
                }
            }
            return {
                passwordInfo: {
                    password: "",
                    reEnterPassword: ""
                },
                resetPwdRules: {
                    password: [
                        {
                            required: true,
                            message: this.$t('tips.resetPwd.pleaseInputThePassword'),//'Please input the password',
                            trigger: 'blur'
                        },
                        {
                            min: 6, max: 15,
                            message: this.$t('globalText.mustContainBetween_X_To_Y_Characters', {min: 6, max: 15}), // 'Must contain between 6 to 15 characters',
                            trigger: ['blur']
                        }
                        ],
                    reEnterPassword: [
                        {
                            required: true,
                            message: 'Please re-Enter the password',
                            trigger: ['blur']
                        },
                        {
                            validator: checkPassword, trigger: ['blur', 'change']
                        },
                    ]
                },
                success:false
            }
        },
        computed: {
            newPassword() {
                return this.$t('myAccount.newPassword') + ':';
            },
            reEnterPassword() {
                return this.$t('myAccount.reEnterNewPassword') + ':';
            }
        },
        methods: {
            submit() {
                this.$refs.resetPwdForm.validate((valid) => {
                    if (valid) {
                        console.log(this.passwordInfo.password);
                        this.success = true;
                    }

                })
            },
            login(){
                this.$router.push({path: '/login'}).catch();
            }

        }
    }
</script>

<style scoped lang="stylus">
    @import "~@/assets/stylus/color.styl"
    .info-area
        margin-top 30px
        margin-left 30px
        line-height 24px

    .password-area
        margin-top 40px
        margin-left 30px

    .reset-password-area
        margin-top 30px
        margin-left 30px

    .button-area
        margin-top 40px
        text-align center
        padding 0 30px
        .submit-button
            background-color $color_blue_second
            width 100%
            font-size 16px
            color white
    .success-content
        text-align center
        .success-icon
            height 120px
            line-height 120px
            font-size 50px
            color $color_success
        .success-info
            height 28px
            line-height 28px
            font-size 16px

</style>
